<template>
  <v-calendar
    ref="calendar"
    v-model="cal"
    color="primary"
    type="day"
    first-time="08:00"
    interval-count="15"
    interval-width="90"
    mode="stack"
    locale="fr"
    :events="orderedplanning"
    @click:time="setTimeDay"
  ></v-calendar>
</template>

<script>

export default {
  components: {
  },
  props: {
    date: {
      type: Date,
      default: () => new Date(),
    },
    time: {
      type: String,
      default: '00:01',
    },
  },
  data() {
    return {
      cal: '',
      tasks: [],
      rappels: [],
      entrees: [],
      sorties: [],
      definedTime: '00:01',
    }
  },
  computed: {
    newEvent() {
      let hours = ''

      hours = this.definedTime.split(':')
      const start = new Date(new Date(this.date).setHours(hours[0], hours[1]))

      return {
        name: 'Prochain Rappel',
        category: 'nouveau',
        color: 'success',
        start: start.getTime(),
        end: new Date(start.getTime() + 15 * 60000),
        ekey: 'nouveau',
        timed: true,
      }
    },
    orderedplanning() {
      // const tasks = this.$store.getters.getTasksByDate(this.date)
      const results = []
      this.rappels.forEach(el => {
        // const isAllDay = (new Date(el.rappel).getHours() === 0 && new Date(el.rappel).getMinutes() === 1)
        results.push(
          {
            name: `Rappel : ${el.nom} ${el.prenom}`,
            category: 'rappels',
            color: 'error',
            ekey: `rappel${el.id}`,
            start: new Date(new Date(el.rappel).getTime()),
            end: new Date(new Date(el.rappel).getTime() + 15 * 60000),
            timed: true,
          },
        )
      })
      this.entrees.forEach(el => {
        results.push({
          name: `Entrée en formation : ${el.nom} ${el.prenom}`,
          category: 'entrees',
          color: 'primary',
          start: new Date(`${el.debutFormation}T00:00:00`),
          end: new Date(`${el.debutFormation}T23:59:59`),
          ekey: `entree${el.id}`,
          timed: false,
        })
      })
      this.sorties.forEach(el => {
        results.push({
          name: `Sortie de formation : ${el.nom} ${el.prenom}`,
          category: 'sorties',
          color: 'info',
          start: new Date(`${el.finFormation}T00:00:00`),
          end: new Date(`${el.finFormation}T23:59:59`),
          ekey: `sorties${el.id}`,
          timed: false,
        })
      })
      results.push(this.newEvent)

      return results
    },
  },
  watch: {
    date(val) {
      if (new Date(this.cal).getTime() !== new Date(val).getTime()) {
        this.fetchData()
      }
      this.cal = val
    },
    time(val) {
      this.definedTime = val
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      // this.$store.dispatch.getTasks()
      const simpleDate = this.$date(this.date, 'yyyy-MM-dd')
      Promise.all([this.fetchStudentRappels(simpleDate), this.fetchStudentEntrees(simpleDate), this.fetchStudentSorties(simpleDate)])
        .then(values => {
          [this.rappels, this.entrees, this.sorties] = values
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.loading = false
        })
    },
    async fetchStudentRappels(date) {
      const dateFormat = new Date(date)
      const fromDate = new Date(dateFormat.setUTCHours(0, 0, 0, 1)).toISOString()
      const toDate = new Date(dateFormat.setUTCHours(23, 59, 59, 59)).toISOString()
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/students?rappeled=false&rappel_gte=${fromDate}&rappel_lte=${toDate}`)

      return response.data
    },
    async fetchStudentEntrees(date) {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/students?debutFormation=${date}`)

      return response.data
    },
    async fetchStudentSorties(date) {
      const response = await this.$http.get(`${process.env.VUE_APP_API_URL}/students?finFormation=${date}`)

      return response.data
    },
    setTimeDay(e) {
      const h = e.time.split(':')[0]
      let m = e.time.split(':')[1]
      m = (Math.round(m / 15) * 15) % 60
      m = m < 10 ? `0${m}` : m

      this.definedTime = `${h}:${m}`
      this.$emit('definedTime', { time: this.definedTime })
    },
  },

}
</script>

<style>

</style>
