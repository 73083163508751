<template>
  <div>
    <template v-if="icon">
      <v-icon
        :color="color"
        @click="dialog = true"
      >
        {{ buttonText }}
      </v-icon>
    </template>
    <v-btn
      v-else
      :color="color"
      dark
      @click="dialog = true"
    >
      {{ buttonText }}
    </v-btn>
    <v-dialog
      v-model="dialog"
      width="700"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Changer la date et l'heure
        </v-card-title>

        <v-card-text>
          <v-row class="justify-center">
            <v-col>
              <v-date-picker
                v-model="newDate"
                full-width
                class="mt-4"
                locale="fr"
              ></v-date-picker>
            </v-col>
            <v-col v-if="!noTime">
              <v-time-picker
                v-model="newTime"
                class="mt-4"
                format="24hr"
                scrollable
              ></v-time-picker>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-text v-if="withTasks">
          <v-switch
            v-model="show"
            label="Voir le calendrier"
          ></v-switch>
          <v-expand-transition>
            <div v-show="show">
              <DateTimePickerCalendarVue
                :date="new Date(newDate)"
                :time="newTime"
                @definedTime="definedTime"
              >
              </DateTimePickerCalendarVue>
            </div>
          </v-expand-transition>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="changeDate"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DateTimePickerCalendarVue from './DateTimePickerCalendar.vue'

export default {
  components: {
    DateTimePickerCalendarVue,
  },
  props: {
    color: {
      type: String,
      default: 'info',
    },
    buttonText: {
      type: String,
      default: 'Modifier la date',
    },
    icon: {
      type: Boolean,
      default: false,
    },
    noTime: {
      type: Boolean,
      default: false,
    },
    withTasks: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      dialog: false,
      newDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      newTime: '00:01',
    }
  },
  mounted() {
    if (this.noTime) {
      this.newTime = '00:01'
    }
  },
  methods: {
    definedTime(payload) {
      this.newTime = payload.time
    },
    changeDate() {
      if (this.noTime) {
        this.newTime = '00:01'
      }
      this.$emit('newDate', new Date(`${this.newDate} ${this.newTime}`))
      this.dialog = false
    },
  },
}
</script>

<style>

</style>
